import React from 'react';

// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

// Components
import Button from '../components/shared/Button';
import Head from '../components/shared/Head';
import Container from '../components/shared/Layout';

// Assets
import home from '../../config/home';
import FontUrl from '../../static/font/refrigerator_deluxe_bold.otf';

const Heading = styled.div`
  ${tw`
    text-6xl
    flex
    justify-center
    items-center
    gap-4
    m-4
    `}
      font-family: Refrigerator Deluxe extra bold;
      text-transform: uppercase

`;
const FrameImg = styled.img`
  ${tw`
    h-96
    `}
`;

const Description = styled.p`
  ${tw`
    row-span-2
    text-center
    text-sm
    text-color-bright
    w-1/2
    md:w-full
    sm:w-full
    m-auto
    `}
`;
const Yellow = styled.span`
  ${tw`
    text-frame-yellow
    `}
`;

const SlimText = styled.span`
  ${tw`
    font-light
    text-white
    `}
  font-size:40px
`;

const Home = () => (
  <>
    <Helmet>
      <title>Selfie Generator | Big Data & AI Toronto 2023</title>
      <meta
        name='Selfie Generator | Big Data & AI Toronto 2023'
        contect='Customiiiiiize your selfie with the colors of Big Data & AI Toronto 2023 and share it on your social media!'
      />
      <link rel='preload' href={FontUrl} as='font' crossorigin='anonymous' type='font/otf' />
    </Helmet>
    <Container flex>
      <Head />
      <FrameImg src={home.frame.src} alt={home.frame.alt} />
      <Heading>
        <h1>
          <Yellow>Say Biiiiiiiiiiiiiiiiig !</Yellow>
        </h1>
      </Heading>
      <Heading>
        <SlimText>YOUR BIG DATA & AI TORONTO 2023 SELFIE GENERATOR</SlimText>
      </Heading>
      <Description>{home.description}</Description>
      <Link to='/frame'>
        <Button>{home.button}</Button>
      </Link>
    </Container>
  </>
);

export default Home;
