/* eslint-disable max-len */
export default {
  logo: {
    src: 'https://mobicheckin-assets.s3.eu-west-1.amazonaws.com/uploads/events/63fc728716669d1fa0af25dd/website/LogoBDAIT-pasCorp-BLANC_000eda11-d09b-453f-89c8-e4a23613d278.png',
    alt: 'logo',
    web: 'https://www.bigdata-toronto.com/',
  },
  frame: {
    src: 'https://res.cloudinary.com/dx3h2gobh/image/upload/v1696344341/my-bdaip-selfie_rkavlg.png',
    alt: 'frame',
  },
  head: 'Big data & AI Toronto',
  description:
    "Customiiiiiize your selfie with the colors of Big Data & AI Toronto 2023 and share it on your social media!",
  subheading: 'Big data & AI Toronto',
  button: 'CONTINUE',
};
